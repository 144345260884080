<template>
  <div class="change-password" style="margin-top:20px">
    <CContainer class="d-flex align-items-center justify-content-center mb-5">
      <CRow class="justify-content-center">
        <CCol md="8" lg="12">
          <CCardGroup class="has-loading-overlay">
            <CCard class="p-4">
              <CCardBody>
                <ValidationObserver ref="reset" v-slot="{ handleSubmit }">
                  <CForm @submit.prevent="handleSubmit(onSubmit)">
                    <h1>Reset</h1>
                    <p class="text-muted">
                      Reseting password for your account
                      {{ getUserDetails.email }}
                    </p>
                    <CRow class="row mb-3">
                      <label class="required col-lg-3 col-md-12"
                        >Old Password</label
                      >
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <ValidationProvider
                          ref="oldPassword"
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="oldPassword"
                            type="password"
                            :value="userDetail.oldPassword"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-3">
                      <label class="required col-lg-3 col-md-12"
                        >New Password</label
                      >
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <ValidationProvider
                          name="password"
                          :rules="{
                            required: true,
                            password_length: 8,
                            password_strength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                          }"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="new_password"
                            type="password"
                            :value="userDetail.new_password"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                          <small class="hint"
                            >Password format: 8 characters - containing upper &
                            lower case letters, numbers and a special character.
                            No words.</small
                          >
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="row mb-3">
                      <label class="required col-lg-3 col-md-12"
                        >Confirm Password</label
                      >
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <ValidationProvider
                          rules="required|confirmed:password"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="confirmPassword"
                            :value="confirmPassword"
                            @input="handleConfirmPassword"
                            type="password"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow>
                      <CCol col="6">
                        <CButton
                          type="button"
                          color="danger"
                          class="px-4"
                          @click="redirectToPrevPage"
                          >{{ "Cancel" }}</CButton
                        >
                      </CCol>
                      <CCol col="6">
                        <CButton type="submit" color="primary" class="px-4">{{
                          "Reset"
                        }}</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import Vue from "vue";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
} from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});

import TextInput from "@/components/reusable/Fields/TextInput";
import { mapActions, mapGetters } from "vuex";
import { parseJwt } from "@/helpers/helper";
export default {
  name: "Reset",
  components: {
    TextInput,
  },
  data() {
    return {
      userDetail: {
        oldPassword: "",
        username: "",
        password: "",
      },
      confirmPassword: "",
    };
  },
  computed: {
    ...mapGetters(["getUserDetails", "getPreviousPageURL"]),
    userEmail() {
      return this.getUserDetails?.email;
    },
  },
  methods: {
    ...mapActions(["checkOldPassword", "changePassword","showToast"]),
    handleConfirmPassword(name, value) {
      this.confirmPassword = value;
    },
    handleInput(name, value) {
      Vue.set(this.userDetail, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.reset.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "passwords doesnot match criteria",
        });
        return;
      }
      let appendAction = this.checkOldPassword(this.userDetail);
      Promise.all([appendAction]).then(async (res) => {
        if (res[0]?.status === 200) {
          await this.changePassword(this.userDetail);
        } else if (res[0]?.response?.status === 400) {
          this.$refs.oldPassword.applyResult({
            errors: ["Old Password Mismatch"],
            valid: false,
            failedRules: {},
          });
        }
      });
    },
    redirectToPrevPage() {
      if (this.getPreviousPageURL)
        this.$router.push({ path: this.getPreviousPageURL });
    },
  },
  mounted() {
    this.userDetail["username"] = this.userEmail;
  },
};
</script>